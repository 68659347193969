import React from 'react';
import { connect } from 'react-redux';

import { Button, SearchInput, DataTable, Pager, Dialog, Bubble } from 'tyb';

const { loadTemplateConfigs, loadDistributionList, templateDistribution } = iceStarkData.store.get('commonAction')?.codeTempConfig
const { updateCodeParamsDetailDialogStatus } = iceStarkData.store.get('commonAction')?.ui;
const { loadEnterprisesSelections } = iceStarkData.store.get('commonAction')?.enterprises
import { handSortOrder } from 'ucode-utils';
import { formatDate } from 'ucode-utils';
import './index.scss';

import AwardAddModal from '../ResourceDistribution/CodeSourceManagement/Components/AwardAddModal';

@connect(
  state => ({
    loading: state.codeTempConfig.loadTemplateConfigsLoading,
    templateConfigs: state.codeTempConfig.templateConfigs,
    codeParamsDetail: state.codePrintingConfig.codeParamsDetail,
    codeParamsDetailDialogStatus: state.ui.codeParamsDetailDialogStatus,
    permissionIds: state.users.permissionIds||[],
    enterprisesSelectionsList: state.enterprises.enterprisesSelectionsList,
  }),
  { 
    loadTemplateConfigs: loadTemplateConfigs.REQUEST,
    updateCodeParamsDetailDialogStatus,
    templateDistribution: templateDistribution.REQUEST,
    loadDistributionList: loadDistributionList.REQUEST,
    loadEnterprisesSelections: loadEnterprisesSelections.REQUEST,
  }
)
class CodeTempConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: this.getTabColumns(),
      perPageCountList: [20, 50, 100],
      sortIdOrder: null,
      params: {
        name: '',
        page: 0,
        size: 20,
        sort: 'id,desc'
      },

      awardAddModal: {
        show: false,
        data: [],
        list: [],
      },
    }
  }

  componentDidMount() {
    this.props.loadTemplateConfigs({});
    this.props.loadEnterprisesSelections();
  }

  changeSearchBox = (searchText) => {
    const params = this.state.params;
    params.name = searchText
    this.setState({
      params
    })
  }

  search = () => {
    const params = this.state.params;
    params.page = 0;
    this.props.loadTemplateConfigs(params);
  }

  handleCreate = () => {
    
    this.props.history.push('/codetempConfig/create');
  }
  handleEdit=(record)=>{
    
    this.props.history.push(`/codetempConfig/edit?id=${record.id}`);
  }

  getTabColumns = () => {
    const { permissionIds } = this.props;
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: '配置/ID',
        // render: (value, record) => <div>
        //   <div title={record.name}><span className="text-overflow">{record.name}</span></div>
        //   <div title={value}><span className="text-overflow">{value}</span></div>
        // </div>
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: '配置名称',
      },
      {
        key: 'tagMatName',
        dataIndex: 'tagMatName',
        title: '赋码材料',
        // render: (value) => <div title={this.state.passwordComplexity[value]}>
        //   <span className="text-overflow">{this.state.passwordComplexity[value]}</span>
        // </div>
      },
      {
        key: 'printType',
        dataIndex: 'printType',
        title: '印刷方式',
      },
      // {
      //   key: 'updatedTime',
      //   dataIndex: 'updatedTime',
      //   title: '最后修改时间',
      //   render: (value) => <div title={formatDate(value)}>
      //     <span className="text-overflow">{formatDate(value)}</span>
      //   </div>
      // },
      {
        key: 'op',
        dataIndex: 'op',
        title: '操作',
        render: (value, record) =>
          <div className="table-operate-btn">
            {
              permissionIds.includes('ws.tempConfig.edit') &&
              <span onClick={() => {
                this.handleEdit(record)
              }}>编辑</span>
            }
            {
                      permissionIds.includes('ws.tempConfig.distribution') &&
                      <span onClick={() => {this.awardAddModalShow(true, record.id)}}>分配</span>
            }
        
          </div>
      },
    ]
  }

  getColumns = () => {
    return this.getTabColumns().map(column => {
      // if (column.key === 'id') {
      //   return {
      //     ...column,
      //     headerRender: this.renderIdSortHeader
      //   }
      // } else {
        return column;
      // }
    });
  }

  handleSortId = () => {
    const newOrder = handSortOrder(this.state.sortIdOrder);
    const params = this.state.params;
    const sort = `id,${newOrder}`;
    params.sort = sort;
    this.setState({ params, sortIdOrder: newOrder });
    this.props.loadTemplateConfigs(params);
  }

  renderIdSortHeader = (col) => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortIdOrder}
        onSort={this.handleSortId}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    )
  }

  onPageChange = (val) => {
    const { params } = this.state;
    params.page = val;
    this.setState({ params });
    this.props.loadTemplateConfigs(params);
  }

  onPerPageChange = (val) => {
    const params = this.state.params;
    params.size = val;
    params.page = 0;
    this.setState({
      params
    });
    this.props.loadTemplateConfigs(params);
  }

  distribution = (id) => () => {
    this.props.loadDistributionList(id);
  }

  handleCancel = () => {
    this.props.updateCodeParamsDetailDialogStatus(false);
  }

  //新增奖项弹窗
  awardAddModalShow = (show, id, index, e) => {
    let newArray = {};
    if (show) {
        this.props.loadDistributionList(
            {
                data: {
                    id: id,
                },
                callback: (res) => {
                    newArray = {
                        show: show,
                        data: res.eseIds,
                        list: this.props.enterprisesSelectionsList,
                        id: id,
                        defAll: res.configAll,
                    }
                    //   console.log(res)
                    this.setState({ awardAddModal: newArray })
                }
            });

      }
      else {
          newArray = {
              show: show,
              data: [],
              list: [],
              id: '',
              defAll: true,
          }
          this.setState({ awardAddModal: newArray })
      }
  }
  //子组件弹窗用新增奖项
  addAwardData = (data, id, defAll) => {
      if (data.length >= 0) {
          this.props.templateDistribution(
              {
                  data: {
                      configId: id,
                      eseIds: defAll ? [] : data,
                      configAll: defAll,
                  },
                  callback: () => {
                      this.awardAddModalShow(false);
                  }
              });
      }
      else {
          this.awardAddModalShow(false);
      }
  }


  render() {
    const { params, tableColumns, perPageCountList, awardAddModal } = this.state;
    const { templateConfigs = {}, loading, codeParamsDetailDialogStatus, codeParamsDetail = {}, permissionIds } = this.props;
    const templateConfigsLength = templateConfigs.content ? templateConfigs.content.length : 0 
    return (
      <div className={`CodePrintingConfig`}>
        <div className="search-bar">
        <div className="tc-15-bubble-icon">
            {
              permissionIds.includes('ws.tempConfig.add') && 
              <Button  onClick={this.handleCreate} >新增印刷配置</Button>
            }
            {/* { codePrintingConfigList.totalElements >= 20 && <Bubble position="bottom">只可建20个码印刷规格</Bubble> } */}
        </div>

          <div className="search-input-block">
            <SearchInput
              onChange={this.changeSearchBox}
              onSearch={this.search}
              value={params.name}
              placeholder="输入配置名称搜索"
            />
          </div>
        </div>

        <DataTable
          // bodyStyle={{ width: '100%', maxWidth: '100%' }}
          columns={this.getColumns()}
          data={templateConfigs.content}
          rowKey="id"
          loading={loading}
          messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) { 
              return <span className="text-weak">暂无码印刷规格，您可以点击左上角<span onClick={this.handleCreate}>新增印刷配置</span></span>; }
          }}
        />

        { templateConfigsLength > 0 && !loading ? <div className="page-padding20">
          <Pager 
            total={templateConfigs.totalElements}
            current={params.page}
            perPageCountList={perPageCountList}
            perPageCount={params.size}
            onPageChange={this.onPageChange}
            onPerPageChange={this.onPerPageChange}
          />
        </div> : '' }
        <Dialog
          title="码参数详情"
          visible={codeParamsDetailDialogStatus}
          onCancel={this.handleCancel}
          style={{ width: '288px' }}
          footerRender={() => 
          <div className="tc-15-rich-dialog-ft-btn-wrap">
            <Button className="weak-button" onClick={this.handleCancel}>关闭</Button>
          </div>}
        >
          <div >
            <span style={{color: '#888888'}}>二维码编码长度</span><span style={{ marginLeft: '20px', color: '#000' }}>{codeParamsDetail.length}</span>
          </div>
          <div style={{marginTop: '20px'}}>
            <span style={{color: '#888888'}}>二维码域名</span><span style={{ marginLeft: '49px', color: '#000' }}>{codeParamsDetail.enterpriseDomain ? `${codeParamsDetail.enterpriseDomain.protocol}${codeParamsDetail.enterpriseDomain.domain}/${codeParamsDetail.enterpriseDomain.subDomain ? (codeParamsDetail.enterpriseDomain.subDomain + '/') : ''}` : ''}</span>
          </div>
        </Dialog>

        {awardAddModal.show && <AwardAddModal
          // key={Math.random()}
          awardAddModal={awardAddModal}
          onAwardAddModalShow={this.awardAddModalShow}
          onAddAwardData={this.addAwardData}
        ></AwardAddModal>
        }
      </div>
    )
  }
}

export default CodeTempConfig;