import React from 'react';
import { Button, Dialog, ListSelector } from 'tyb';
const { notification } = iceStarkData.store.get('commonAction')?.ui;
import { connect } from 'react-redux';

import { RadioButtonGroup } from 'tyb';

@connect(
    (state) => ({
    }),
    {
        notification: notification,
    }
)
class AwardAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            list: [],
            defAll: true,
        };

    }
    //保存修改
    modalOk = () => {
        const { onAddAwardData, awardAddModal, notification } = this.props;
        const { data, defAll } = this.state;
        if (data && data.length == 0 && !defAll) {
            notification({ emsg: '请选择企业', ecode: '500' })
        }
        else {
            // let newData = callbackList.filter(key => data.indexOf(key.id) >= 0)
            onAddAwardData(data, awardAddModal.id, this.state.defAll);
        }
    };

    //取消修改
    modelCancel = () => {
        const { onAwardAddModalShow } = this.props;
        onAwardAddModalShow(false);
    }

    DummyTransferChange = (a) => {
        this.setState({ data: a })
    }

    componentDidMount() {
        const { awardAddModal } = this.props;

        let newarray = JSON.parse(JSON.stringify(awardAddModal.list))


        for (let i = 0; i < newarray.length; i++) {
            newarray[i].id = newarray[i].value;

        }
        this.setState({ list: newarray, data: awardAddModal.data, defAll: awardAddModal.defAll })

    }


    render() {
        const { data, list } = this.state;
        const { awardAddModal } = this.props;
        return (
            <div>

                <Dialog
                    className={this.state.defAll ? 'commonModal' : 'commonModal awardAddModal'}
                    style={this.state.defAll ? { width: 287, minWidth: 287 } : { width: 770 }}
                    title='分配'
                    visible={awardAddModal.show}
                    // visible={true}
                    okText="确认"
                    cancelText="取消"
                    onOk={() => { this.modalOk(false) }}
                    onCancel={() => { this.modelCancel(false) }}
                    shouldCloseOnOverlayClick={false}>

                    <div style={!this.state.defAll ? { marginBottom: 20 } : { marginLeft: 16 }}>
                        <RadioButtonGroup
                            style={{ marginLeft: 20 }}
                            value={this.state.defAll}
                            list={[
                                {
                                    title: '所有企业',
                                    text: '所有企业',
                                    value: true
                                },
                                {
                                    title: '分配部分企业',
                                    text: '分配部分企业',
                                    value: false
                                }
                            ]}
                            onChange={(value) => {
                                this.setState({
                                    defAll: value
                                })
                            }}
                        />
                    </div>
                    {!this.state.defAll && <ListSelector
                        value={data}
                        list={list}
                        prepareItemTextFn={(item) => {
                            return <span title={item.text} style={{ cursor: 'default' }}  >{item.text}</span>
                        }
                        }
                        selectedItemTextFn={(item) => {
                            return <span title={item.text} style={{ cursor: 'default' }}>{item.text}</span>
                        }
                        }
                        onChange={(value) => {
                            this.DummyTransferChange(value)
                        }}
                    ></ListSelector>}
                </Dialog>


            </div >
        )
    }
}
export default AwardAddModal;